import { Component } from "@angular/core";

@Component({
  selector: "app-letter-assistant",
  standalone: true,
  imports: [],
  templateUrl: "./letter-assistant.component.html"
})
export class LetterAssistantComponent {

}