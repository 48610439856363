// Import node modules
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgxSpinnerModule } from "ngx-spinner";

// Import PrimeNG modules
import { MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { RippleModule } from "primeng/ripple";
import { StyleClassModule } from "primeng/styleclass";
import { ToastModule } from "primeng/toast";
import { DropdownModule } from "primeng/dropdown";
import { PickListModule } from "primeng/picklist";
import { SplitterModule } from "primeng/splitter";


// Import Services
import { APIService } from "./services/api.service";
import { UtilService } from "./services/util.service";

// Import Components
import { AppComponent } from "src/app/app.component";
// Import layout Components
import { LayoutComponent } from "src/app/components/layout/layout.component";
import { FooterComponent } from "src/app/components/layout/footer/footer.component";
// Import landing Components
import { AreaMapComponent } from "src/app/components/landing/area-map/area-map.component";
import { PricingComponent } from "src/app/components/landing/pricing/pricing.component";
import { AboutComponent } from "src/app/components/landing/about/about.component";
import { CareersComponent } from "src/app/components/landing/careers/careers.component";
import { CookiePolicyComponent } from "src/app/components/landing/cookie-policy/cookie-policy.component";
import { TermsConditionsComponent } from "src/app/components/landing/terms-conditions/terms-conditions.component";
import { PrivacyPolicyComponent } from "src/app/components/landing/privacy-policy/privacy-policy.component";
import { KnowledgeBaseComponent } from "src/app/components/landing/knowledge-base/knowledge-base.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { CommonModule } from "@angular/common";
import { PostLetterHistoryComponent } from "./components/post-letter-history/post-letter-history.component";
import { LetterAssistantComponent } from "./components/letter-assistant/letter-assistant.component";

// Routes
const routes: Routes = [
    {
        path: "", component: LayoutComponent,
        children: [
            { path: "postcodes", loadChildren: () => import("src/app/components/postcode/postcode.module").then(route => route.PostcodeModule) },
            { path: "sectors", loadChildren: () => import("src/app/components/sector/sector.module").then(route => route.SectorModule) },
            { path: "user", loadChildren: () => import("src/app/components/user-register/user-register.module").then(route => route.UserRegisterModule) },
            { path: "auth", loadChildren: () => import("src/app/components/auth/auth.module").then(route => route.AuthModule) },
            { path: "support", loadChildren: () => import("src/app/components/landing/support/support.module").then(m => m.SupportModule) },
            { path: "letter-assistant", component: LetterAssistantComponent },
            { path: "area-map", component: AreaMapComponent },
            { path: "about", component: AboutComponent },
            { path: "careers", component: CareersComponent },
            { path: "cookie-policy", component: CookiePolicyComponent },
            { path: "knowledge-base", component: KnowledgeBaseComponent },
            { path: "pricing", component: PricingComponent },
            { path: "privacy-policy", component: PrivacyPolicyComponent },
            { path: "terms-conditions", component: TermsConditionsComponent },

            { path: "profile", loadChildren: () => import("src/app/components/profile/profile.module").then(m => m.ProfileModule) },
            { path: "notifications", component: NotificationsComponent },
            { path: "post-letter-history", component: PostLetterHistoryComponent },

            // Default
            { path: "**", redirectTo: "profile", pathMatch: "full" }
        ]
    }
];

@NgModule({
    declarations: [
        // App
        AppComponent,
        // Layout
        LayoutComponent,
        FooterComponent,
        // Landing
        AreaMapComponent,
        PricingComponent,
        AboutComponent,
        CareersComponent,
        CookiePolicyComponent,
        TermsConditionsComponent,
        KnowledgeBaseComponent,
        PrivacyPolicyComponent,
        NotificationsComponent,
        PostLetterHistoryComponent
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [CommonModule,
        RouterModule.forRoot(routes),
        BrowserAnimationsModule,
        FormsModule, ReactiveFormsModule,
        NgxSpinnerModule,
        // Import PrimeNG modules
        ButtonModule,
        RippleModule,
        StyleClassModule,
        ToastModule,
        SplitterModule,
        DropdownModule,
        PickListModule
    ],
    providers: [
        APIService,
        UtilService,
        MessageService,
        provideHttpClient(withInterceptorsFromDi())
    ]
})

export class AppModule { }