<!-- Terms Conditions Section -->
<h1>Terms and Conditions</h1>
<p>Welcome to SendB2B.co.uk. If you continue to browse and use this website, you are agreeing to comply with and be
  bound by the following terms and conditions of use, which together with our privacy policy govern SendB2B's
  relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please
  do not use our website.</p>

<h2>1. Definitions</h2>
<p>The term 'SendB2B' or 'us' or 'we' refers to the owner of the website whose registered office is [Your Company
  Address]. Our company registration number is [Company Registration Number]. The term 'you' refers to the user or
  viewer of our website.</p>

<h2>2. Use of the Website</h2>
<p>
  2.1 The content of the pages of this website is for your general information and use only. It is subject to change
  without notice.<br>
  2.2 This website uses cookies to monitor browsing preferences. If you do allow cookies to be used, the following
  personal information may be stored by us for use by third parties.<br>
  2.3 Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance,
  completeness or suitability of the information and materials found or offered on this website for any particular
  purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly
  exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.<br>
  2.4 Your use of any information or materials on this website is entirely at your own risk, for which we shall not be
  liable. It shall be your own responsibility to ensure that any products, services or information available through
  this website meet your specific requirements.
</p>

<h2>3. Intellectual Property</h2>
<p>
  3.1 This website contains material which is owned by or licensed to us. This material includes, but is not limited to,
  the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the
  copyright notice, which forms part of these terms and conditions.<br>
  3.2 All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are
  acknowledged on the website.<br>
  3.3 Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence.
</p>

<h2>4. Links to Other Websites</h2>
<p>From time to time, this website may also include links to other websites. These links are provided for your
  convenience to provide further information. They do not signify that we endorse the website(s). We have no
  responsibility for the content of the linked website(s).</p>

<h2>5. Limitation of Liability</h2>
<p>
  5.1 To the fullest extent permitted by law, SendB2B shall not be liable for any indirect, incidental, special or
  consequential damages, or loss of profits or revenue, arising out of the use or inability to use this website.<br>
  5.2 Nothing in these terms and conditions shall exclude or limit liability for (i) death or personal injury caused by
  negligence, (ii) fraud or fraudulent misrepresentation, or (iii) any other liability which cannot be excluded or
  limited under applicable law.
</p>

<h2>6. Amendments to the Terms</h2>
<p>SendB2B reserves the right to amend these terms and conditions at any time. Any such amendments will be posted on
  this website and will be effective immediately upon being posted.</p>

<h2>7. Governing Law</h2>
<p>Your use of this website and any dispute arising out of such use of the website is subject to the laws of England,
  Northern Ireland, Scotland and Wales.</p>

<h2>8. Contact Us</h2>
<p>If you have any questions about these Terms and Conditions, please contact us at <a
    href="mailto:info@sendb2b.co.uk">info&#64;sendb2b.co.uk</a>.</p>