<!-- Privacy-policy Section -->
<div><strong>PRIVACY POLICY</strong></div>
<div><strong>Last updated&nbsp;March 22, 2023</strong></div>
<div>This privacy notice for&nbsp;01 Data Ltd&nbsp;(doing business as&nbsp;Send B2B)&nbsp;('<strong>Send B2B</strong>',
  '<strong>we</strong>', '<strong>us</strong>', or '<strong>our</strong>'), describes how and why we might collect,
  store, use, and/or share ('<strong>process</strong>') your information when you use our services
  ('<strong>Services</strong>'), such as when you:</div>
<ul>
  <li>Visit our website&nbsp;at&nbsp;sendb2b.co.uk, or any website of ours that links to this privacy notice</li>
</ul>
<div>
  <ul>
    <li>Engage with us in other related ways, including any sales, marketing, or events</li>
  </ul>
  <div><strong>Questions or concerns?&nbsp;</strong>Reading this privacy notice will help you understand your privacy
    rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you
    still have any questions or concerns, please contact us at&nbsp;hello&#64;sendb2b.co.uk.</div>
  <div><strong>SUMMARY OF KEY POINTS</strong></div>
  <div><strong><em>This summary provides key points from our privacy notice, but you can find out more details about any
        of these topics by continuing to read below.</em></strong></div>
  <div><strong>What personal information do we process?</strong>&nbsp;When you visit, use, or navigate our Services, we
    may process personal information depending on how you interact with&nbsp;Send B2B&nbsp;and the Services, the choices
    you make, and the products and features you use. Learn more about personal information you disclose to us.</div>
  <div><strong>Do we process any sensitive personal information?</strong>&nbsp;We do not process sensitive personal
    information.</div>
  <div><strong>Do we receive any information from third parties?</strong>&nbsp;We do not receive any information from
    third parties.</div>
  <div><strong>How do we process your information?</strong>&nbsp;We process your information to provide, improve, and
    administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may
    also process your information for other purposes with your consent. We process your information only when we have a
    valid legal reason to do so. Learn more about how we process your information.</div>
  <div><strong>In what situations and with which&nbsp;types of&nbsp;parties do we share personal
      information?</strong>&nbsp;We may share information in specific situations and with specific&nbsp;categories
    of&nbsp;third parties. Learn more about when and with whom we share your personal information.</div>
  <div><strong>How do we keep your information safe?</strong>&nbsp;We have&nbsp;organisational&nbsp;and technical
    processes and procedures in place to protect your personal information. However, no electronic transmission over the
    internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee
    that hackers, cybercriminals, or other&nbsp;unauthorised&nbsp;third parties will not be able to defeat our security
    and improperly collect, access, steal, or modify your information. Learn more about how we keep your information
    safe.</div>
  <div><strong>What are your rights?</strong>&nbsp;Depending on where you are located geographically, the applicable
    privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy
    rights.</div>
  <div><strong>How do you exercise your rights?</strong>&nbsp;The easiest way to exercise your rights is
    by&nbsp;visiting&nbsp;sendb2b.co.uk/support, or by contacting us. We will consider and act upon any request in
    accordance with applicable data protection laws.</div>
  <div>Want to learn more about what&nbsp;Send B2B&nbsp;does with any information we collect?&nbsp;Review the privacy
    notice in full.</div>

  <div><strong>TABLE OF CONTENTS</strong></div>
  <ul>
    <li>1. WHAT INFORMATION DO WE COLLECT?</li>
    <li>2. HOW DO WE PROCESS YOUR INFORMATION?</li>
    <li>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</li>
    <li>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
    <li>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
    <li>6. HOW LONG DO WE KEEP YOUR INFORMATION?</li>
    <li>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
    <li>8. DO WE COLLECT INFORMATION FROM MINORS?</li>
    <li>9. WHAT ARE YOUR PRIVACY RIGHTS?</li>
    <li>10. CONTROLS FOR DO-NOT-TRACK FEATURES</li>
    <li>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
    <li>12. DO WE MAKE UPDATES TO THIS NOTICE?</li>
    <li>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
    <li>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</li>
  </ul>
  <div><strong>1. WHAT INFORMATION DO WE COLLECT?</strong></div>
  <div><strong>Personal information you disclose to us</strong></div>
  <div>
    <div><strong><em>In Short:</em></strong><em>We collect personal information that you provide to us.</em></div>
  </div>
  <div>We collect personal information that you voluntarily provide to us when you&nbsp;register on the
    Services,&nbsp;express an interest in obtaining information about us or our products and Services, when you
    participate in activities on the Services, or otherwise when you contact us.</div>
  <div><strong>Personal Information Provided by You.</strong>&nbsp;The personal information that we collect depends on
    the context of your interactions with us and the Services, the choices you make, and the products and features you
    use. The personal information we collect may include the following:</div>
  <ul>
    <li>debit/credit card numbers</li>
  </ul>
  <ul>
    <li>billing addresses</li>
  </ul>
  <ul>
    <li>contact or authentication data</li>
  </ul>
  <ul>
    <li>names</li>
  </ul>
  <ul>
    <li>phone numbers</li>
  </ul>
  <ul>
    <li>email addresses</li>
  </ul>
  <ul>
    <li>mailing addresses</li>
  </ul>
  <ul>
    <li>usernames</li>
  </ul>
  <ul>
    <li>passwords</li>
  </ul>
  <ul>
    <li>contact preferences</li>
  </ul>
  <div><strong>Sensitive Information.</strong>&nbsp;We do not process sensitive information.</div>
  <div><strong>Payment Data.</strong>&nbsp;We may collect data necessary to process your payment if you make purchases,
    such as your payment instrument number, and the security code associated with your payment instrument. All payment
    data is stored by&nbsp;Stripe. You may find their privacy notice link(s) here:&nbsp;<a
      href="https://stripe.com/gb/privacy" target="_blank" data-custom-class="link">https://stripe.com/gb/privacy</a>.
  </div>
  <div>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of
    any changes to such personal information.</div>
  <div><strong>Information automatically collected</strong></div>
  <div>
    <div><strong><em>In Short:</em></strong><em>Some information &mdash; such as your Internet Protocol (IP) address
        and/or browser and device characteristics &mdash; is collected automatically when you visit our Services.</em>
    </div>
  </div>
  <div>We automatically collect certain information when you visit, use, or navigate the Services. This information does
    not reveal your specific identity (like your name or contact information) but may include device and usage
    information, such as your IP address, browser and device characteristics, operating system, language preferences,
    referring URLs, device name, country, location, information about how and when you use our Services, and other
    technical information. This information is primarily needed to maintain the security and operation of our Services,
    and for our internal analytics and reporting purposes.</div>
  <div>Like many businesses, we also collect information through cookies and similar technologies.&nbsp;You can find out
    more about this in our Cookie Notice:&nbsp;sendb2b.co.uk/cookiepolicy.</div>
  <div><strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong></div>
  <div>
    <div><strong><em>In Short:&nbsp;</em></strong><em>We process your information to provide, improve, and administer
        our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also
        process your information for other purposes with your consent.</em></div>
  </div>
  <div><strong>We process your personal information for a variety of reasons, depending on how you interact with our
      Services, including:</strong></div>
  <ul>
    <li><strong>To facilitate account creation and authentication and otherwise manage user accounts.&nbsp;</strong>We
      may process your information so you can create and log in to your account, as well as keep your account in working
      order.</li>
  </ul>
  <div>
    <ul>
      <li><strong>To deliver and facilitate delivery of services to the user.&nbsp;</strong>We may process your
        information to provide you with the requested service.</li>
    </ul>
    <div>
      <ul>
        <li><strong>To respond to user inquiries/offer support to users.&nbsp;</strong>We may process your information
          to respond to your inquiries and solve any potential issues you might have with the requested service.</li>
      </ul>
      <div>
        <ul>
          <li><strong>To send administrative information to you.&nbsp;</strong>We may process your information to send
            you details about our products and services, changes to our terms and policies, and other similar
            information.</li>
        </ul>
        <div>
          <ul>
            <li><strong>To&nbsp;fulfil&nbsp;and manage your orders.</strong>&nbsp;We may process your information
              to&nbsp;fulfil&nbsp;and manage your orders, payments, returns, and exchanges made through the Services.
            </li>
          </ul>
          <div>
            <div>
              <div>
                <div>
                  <div>
                    <div>
                      <div>
                        <div>
                          <div>
                            <div>
                              <div>
                                <div>
                                  <div>
                                    <div>
                                      <ul>
                                        <li><strong>To save or protect an individual's vital interest.</strong>&nbsp;We
                                          may process your information when necessary to save or protect an
                                          individual&rsquo;s vital interest, such as to prevent harm.</li>
                                      </ul>
                                      <div><strong>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
                                          INFORMATION?</strong></div>
                                      <div><em><strong>In Short:&nbsp;</strong>We only process your personal information
                                          when we believe it is necessary and we have a valid legal reason
                                          (i.e.&nbsp;legal basis) to do so under applicable law, like with your consent,
                                          to comply with laws, to provide you with services to enter into
                                          or&nbsp;fulfil&nbsp;our contractual obligations, to protect your rights, or
                                          to&nbsp;fulfil&nbsp;our legitimate business interests.</em></div>
                                      <div>The General Data Protection Regulation (GDPR) and UK GDPR require us to
                                        explain the valid legal bases we rely on in order to process your personal
                                        information. As such, we may rely on the following legal bases to process your
                                        personal information:</div>
                                      <ul>
                                        <li><strong>Consent.&nbsp;</strong>We may process your information if you have
                                          given us permission (i.e.&nbsp;consent) to use your personal information for a
                                          specific purpose. You can withdraw your consent at any time. Learn more about
                                          withdrawing your consent.</li>
                                      </ul>

                                      <ul>
                                        <li><strong>Performance of a Contract.</strong>&nbsp;We may process your
                                          personal information when we believe it is necessary to&nbsp;fulfil&nbsp;our
                                          contractual obligations to you, including providing our Services or at your
                                          request prior to entering into a contract with you.</li>
                                      </ul>
                                      <ul>
                                        <li><strong>Legal Obligations.</strong>&nbsp;We may process your information
                                          where we believe it is necessary for compliance with our legal obligations,
                                          such as to cooperate with a law enforcement body or regulatory agency,
                                          exercise or defend our legal rights, or disclose your information as evidence
                                          in litigation in which we are involved.</li>
                                      </ul>
                                      <ul>
                                        <li><strong>Vital Interests.</strong>&nbsp;We may process your information where
                                          we believe it is necessary to protect your vital interests or the vital
                                          interests of a third party, such as situations involving potential threats to
                                          the safety of any person.</li>
                                      </ul>
                                      <div><strong>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</strong>
                                      </div>
                                      <div><strong><em>In Short:</em></strong><em>&nbsp;We may share information in
                                          specific situations described in this section and/or with the
                                          following&nbsp;categories of&nbsp;third parties.</em></div>
                                      <div><strong>Vendors, Consultants, and Other Third-Party Service
                                          Providers.</strong>&nbsp;We may share your data with third-party vendors,
                                        service providers, contractors, or agents ('<strong>third parties</strong>') who
                                        perform services for us or on our behalf and require access to such information
                                        to do that work.&nbsp;We have contracts in place with our third parties, which
                                        are designed to help safeguard your personal information. This means that they
                                        cannot do anything with your personal information unless we have instructed them
                                        to do it. They will also not share your personal information with
                                        any&nbsp;organisation&nbsp;apart from us. They also commit to protect the data
                                        they hold on our behalf and to retain it for the period we
                                        instruct.&nbsp;The&nbsp;categories of&nbsp;third parties we may share personal
                                        information with are as follows:</div>
                                      <ul>
                                        <li>Ad Networks</li>
                                      </ul>
                                      <ul>
                                        <li>Affiliate Marketing Programs</li>
                                      </ul>
                                      <ul>
                                        <li>Data Analytics Services</li>
                                      </ul>
                                      <ul>
                                        <li>Order&nbsp;Fulfilment&nbsp;Service Providers</li>
                                      </ul>
                                      <ul>
                                        <li>Payment Processors</li>
                                      </ul>
                                      <ul>
                                        <li>Performance Monitoring Tools</li>
                                      </ul>
                                      <ul>
                                        <li>Retargeting Platforms</li>
                                      </ul>
                                      <ul>
                                        <li>Sales &amp; Marketing Tools</li>
                                      </ul>
                                      <ul>
                                        <li>Testing Tools</li>
                                      </ul>
                                      <ul>
                                        <li>User Account Registration &amp; Authentication Services</li>
                                      </ul>
                                      <div>We&nbsp;also&nbsp;may need to share your personal information in the
                                        following situations:</div>
                                      <ul>
                                        <li><strong>Business Transfers.</strong>&nbsp;We may share or transfer your
                                          information in connection with, or during negotiations of, any merger, sale of
                                          company assets, financing, or acquisition of all or a portion of our business
                                          to another company.</li>
                                      </ul>
                                      <div>
                                        <div>
                                          <div>
                                            <div>
                                              <div>
                                                <div><strong>5. DO WE USE COOKIES AND OTHER TRACKING
                                                    TECHNOLOGIES?</strong></div>
                                                <div><strong><em>In Short:</em></strong><em>&nbsp;We may use cookies and
                                                    other tracking technologies to collect and store your
                                                    information.</em></div>
                                                <div>We may use cookies and similar tracking technologies (like web
                                                  beacons and pixels) to access or store information. Specific
                                                  information about how we use such technologies and how you can refuse
                                                  certain cookies is set out in our Cookie
                                                  Notice:&nbsp;sendb2b.co.uk/cookiepolicy.</div>
                                                <div><strong>6. HOW LONG DO WE KEEP YOUR INFORMATION?</strong></div>
                                                <div><strong><em>In Short:&nbsp;</em></strong><em>We keep your
                                                    information for as long as necessary to&nbsp;fulfil&nbsp;the
                                                    purposes outlined in this privacy notice unless otherwise required
                                                    by law.</em></div>
                                                <div>We will only keep your personal information for as long as it is
                                                  necessary for the purposes set out in this privacy notice, unless a
                                                  longer retention period is required or permitted by law (such as tax,
                                                  accounting, or other legal requirements).&nbsp;No purpose in this
                                                  notice will require us keeping your personal information for longer
                                                  than&nbsp;eighteen (18)&nbsp;months past the start of the idle period
                                                  of the user's account.</div>
                                                <div>When we have no ongoing legitimate business need to process your
                                                  personal information, we will either delete
                                                  or&nbsp;anonymise&nbsp;such information, or, if this is not possible
                                                  (for example, because your personal information has been stored in
                                                  backup archives), then we will securely store your personal
                                                  information and isolate it from any further processing until deletion
                                                  is possible.</div>
                                                <div><strong>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong></div>
                                                <div><strong><em>In Short:&nbsp;</em></strong><em>We aim to protect your
                                                    personal information through a system
                                                    of&nbsp;organisational&nbsp;and technical security measures.</em>
                                                </div>
                                                <div>We have implemented appropriate and reasonable technical
                                                  and&nbsp;organisational&nbsp;security measures designed to protect the
                                                  security of any personal information we process. However, despite our
                                                  safeguards and efforts to secure your information, no electronic
                                                  transmission over the Internet or information storage technology can
                                                  be guaranteed to be 100% secure, so we cannot promise or guarantee
                                                  that hackers, cybercriminals, or other&nbsp;unauthorised&nbsp;third
                                                  parties will not be able to defeat our security and improperly
                                                  collect, access, steal, or modify your information. Although we will
                                                  do our best to protect your personal information, transmission of
                                                  personal information to and from our Services is at your own risk. You
                                                  should only access the Services within a secure environment.</div>
                                                <div><strong>8. DO WE COLLECT INFORMATION FROM MINORS?</strong></div>
                                                <div><strong><em>In Short:</em></strong><em>&nbsp;We do not knowingly
                                                    collect data from or market to&nbsp;children under 18 years of
                                                    age.</em></div>
                                                <div>We do not knowingly solicit data from or market to children under
                                                  18 years of age. By using the Services, you represent that you are at
                                                  least 18 or that you are the parent or guardian of such a minor and
                                                  consent to such minor dependent&rsquo;s use of the Services. If we
                                                  learn that personal information from users less than 18 years of age
                                                  has been collected, we will deactivate the account and take reasonable
                                                  measures to promptly delete such data from our records. If you become
                                                  aware of any data we may have collected from children under age 18,
                                                  please contact us at&nbsp;hello&#64;sendb2b.co.uk.</div>
                                                <div><strong>9. WHAT ARE YOUR PRIVACY RIGHTS?</strong></div>
                                                <div><strong><em>In Short:</em></strong><em>&nbsp;In some regions, such
                                                    as&nbsp;the European Economic Area (EEA) and United Kingdom (UK),
                                                    you have rights that allow you greater access to and control over
                                                    your personal information.&nbsp;You may review, change, or terminate
                                                    your account at any time.</em></div>
                                                <div>In some regions (like the EEA and UK), you have certain rights
                                                  under applicable data protection laws. These may include the right (i)
                                                  to request access and obtain a copy of your personal information, (ii)
                                                  to request rectification or erasure; (iii) to restrict the processing
                                                  of your personal information; and (iv) if applicable, to data
                                                  portability. In certain circumstances, you may also have the right to
                                                  object to the processing of your personal information. You can make
                                                  such a request by contacting us by using the contact details provided
                                                  in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.
                                                </div>
                                                <div>We will consider and act upon any request in accordance with
                                                  applicable data protection laws.</div>
                                                <div>If you are located in the EEA or UK and you believe we are
                                                  unlawfully processing your personal information, you also have the
                                                  right to complain to your&nbsp;<a
                                                    href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                    target="_blank" rel="noopener noreferrer"
                                                    data-custom-class="link">Member State data protection
                                                    authority</a>&nbsp;or&nbsp;<a
                                                    href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                                                    target="_blank" rel="noopener noreferrer">UK data protection
                                                    authority</a>.</div>
                                                <div>If you are located in Switzerland, you may contact the&nbsp;<a
                                                    href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank"
                                                    rel="noopener noreferrer" data-custom-class="link">Federal Data
                                                    Protection and Information Commissioner</a>.</div>
                                                <div><strong><u>Withdrawing your consent:</u></strong>&nbsp;If we are
                                                  relying on your consent to process your personal information,&nbsp;you
                                                  have the right to withdraw your consent at any time. You can withdraw
                                                  your consent at any time by contacting us by using the contact details
                                                  provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'
                                                  below&nbsp;or by updating your preferences.</div>
                                                <div>However, please note that this will not affect the lawfulness of
                                                  the processing before its withdrawal nor,&nbsp;will it affect the
                                                  processing of your personal information conducted in reliance on
                                                  lawful processing grounds other than consent.</div>
                                                <div><strong>Account Information</strong></div>
                                                <div>If you would at any time like to review or change the information
                                                  in your account or terminate your account, you can:</div>
                                                <ul>
                                                  <li>Log in to your account settings and update your user account.</li>
                                                </ul>
                                                <div>Upon your request to terminate your account, we will deactivate or
                                                  delete your account and information from our active databases.
                                                  However, we may retain some information in our files to prevent fraud,
                                                  troubleshoot problems, assist with any investigations, enforce our
                                                  legal terms and/or comply with applicable legal requirements.</div>
                                                <div><strong><u>Cookies and similar technologies:</u></strong>&nbsp;Most
                                                  Web browsers are set to accept cookies by default. If you prefer, you
                                                  can usually choose to set your browser to remove cookies and to reject
                                                  cookies. If you choose to remove cookies or reject cookies, this could
                                                  affect certain features or services of our Services. You may
                                                  also&nbsp;<a href="http://www.aboutads.info/choices/" target="_blank"
                                                    rel="noopener noreferrer" data-custom-class="link">opt out of
                                                    interest-based advertising by advertisers</a>&nbsp;on our
                                                  Services.&nbsp;For further information, please see our Cookie
                                                  Notice:&nbsp;sendb2b.co.uk/cookiepolicy.</div>
                                                <div>If you have questions or comments about your privacy rights, you
                                                  may email us at&nbsp;hello&#64;sendb2b.co.uk.</div>
                                                <div><strong>10. CONTROLS FOR DO-NOT-TRACK FEATURES</strong></div>
                                                <div>Most web browsers and some mobile operating systems and mobile
                                                  applications include a Do-Not-Track ('DNT') feature or setting you can
                                                  activate to signal your privacy preference not to have data about your
                                                  online browsing activities monitored and collected. At this stage no
                                                  uniform technology standard for&nbsp;recognising&nbsp;and implementing
                                                  DNT signals has been&nbsp;finalised. As such, we do not currently
                                                  respond to DNT browser signals or any other mechanism that
                                                  automatically communicates your choice not to be tracked online. If a
                                                  standard for online tracking is adopted that we must follow in the
                                                  future, we will inform you about that practice in a revised version of
                                                  this privacy notice.</div>
                                                <div><strong>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY
                                                    RIGHTS?</strong></div>
                                                <div><strong><em>In Short:&nbsp;</em></strong><em>Yes, if you are a
                                                    resident of California, you are granted specific rights regarding
                                                    access to your personal information.</em></div>
                                                <div>California Civil Code Section 1798.83, also known as
                                                  the&nbsp;'Shine The Light'&nbsp;law, permits our users who are
                                                  California residents to request and obtain from us, once a year and
                                                  free of charge, information about categories of personal information
                                                  (if any) we disclosed to third parties for direct marketing purposes
                                                  and the names and addresses of all third parties with which we shared
                                                  personal information in the immediately preceding calendar year. If
                                                  you are a California resident and would like to make such a request,
                                                  please submit your request in writing to us using the contact
                                                  information provided below.</div>
                                                <div>If you are under 18 years of age, reside in California, and have a
                                                  registered account with Services, you have the right to request
                                                  removal of unwanted data that you publicly post on the Services. To
                                                  request removal of such data, please contact us using the contact
                                                  information provided below and include the email address associated
                                                  with your account and a statement that you reside in California. We
                                                  will make sure the data is not publicly displayed on the Services, but
                                                  please be aware that the data may not be completely or comprehensively
                                                  removed from all our systems (e.g.&nbsp;backups, etc.).</div>
                                                <div><strong>12. DO WE MAKE UPDATES TO THIS NOTICE?</strong></div>
                                                <div><em><strong>In Short:&nbsp;</strong>Yes, we will update this notice
                                                    as necessary to stay compliant with relevant laws.</em></div>
                                                <div>We may update this privacy notice from time to time. The updated
                                                  version will be indicated by an updated&nbsp;'Revised'&nbsp;date and
                                                  the updated version will be effective as soon as it is accessible. If
                                                  we make material changes to this privacy notice, we may notify you
                                                  either by prominently posting a notice of such changes or by directly
                                                  sending you a notification. We encourage you to review this privacy
                                                  notice frequently to be informed of how we are protecting your
                                                  information.</div>
                                                <div><strong>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>
                                                </div>
                                                <div>If you have questions or comments about this notice, you
                                                  may&nbsp;email us at&nbsp;hello&#64;sendb2b.co.uk&nbsp;or by post to:
                                                </div>
                                                <div>01 Data Ltd</div>
                                                <div>124 City Road</div>
                                                <div>London&nbsp;EC1V2NX&nbsp;</div>
                                                <div>United Kingdom</div>
                                                <div><strong>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                                                    COLLECT FROM YOU?</strong></div>
                                                <div>You have the right to request access to the personal information we
                                                  collect from you, change that information, or delete it.&nbsp;To
                                                  request to review, update, or delete your personal information,
                                                  please&nbsp;visit:&nbsp;sendb2b.co.uk/support.</div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>